.menu {
  background-color: #fff;
  color: #500;
  text-align: center;
  contain: content;
}

.menu.dark {
  background-color: #000;
  color: #500;
}

.menu a {
  color: inherit;
  font-size: 2em;
  padding: 2em;
  text-decoration: none;
}

.menu a:visited {
  color: inherit;
  text-decoration: none;
}

.menu.dark a {
  
}

.menu a:hover {
  background-color: #000;
  color: #a00;
}

.menu.dark a:visited {
  color: inherit;
}

.menu.dark a:hover {
  background-color: #500;
  color: #000;
}

@media (max-width: 600px) {
  .menu {
    
  }
  
  .menu a {
    
  }
}

@media (min-width: 960px) {
  .menu {
    
  }
  
  .menu a {
    
  }
}

@media (min-width: 1280px) {
  .menu {
    
  }
  
  .menu a {
    
  }
}
