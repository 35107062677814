document, body {
  margin: 0;
  padding: 0;
}

.banner {
  contain: content;
  min-height: 100vh;
  font-family: 'Almendra', serif;
  position: relative;
  display: flex;
  align-items: center;
}

.banner.headings {
  
}

.banner .background {
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  filter: grayscale(1);
  animation-name: unblur;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@keyframes unblur {
  0% {
    filter: blur(25px) grayscale(1);
  }
  100% {
    filter: blur(0px) grayscale(1);
  }
}

.banner .heading {
  filter: grayscale(0);
  color: #b10909;
  margin: 48px 5vw;
  font-size: 17vw;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  animation-name: fadeIn1;
  text-transform: capitalize;
}

@keyframes fadeIn1 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn2 {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.banner .subheading {
  filter: grayscale(0);
  color: #ddd;
  background-color: #030303;
  margin: 16px 7vw;
  font-size: 8vw;
  animation-duration: 4s;
  animation-timing-function: ease-out;
  animation-name: fadeIn2;
  text-transform: capitalize;
}
