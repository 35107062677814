.entry {
  padding: 64px;
  background-color: #000;
  display: relative;
}

.entry.retracted {}

.entry .header {
  font-size: 5vw;
  color: #0286e4;
  font-family: 'Almendra', serif;
  text-transform: capitalize;
  margin-bottom: 16px;
}
.entry.retracted .header {
  text-decoration: line-through;
  text-decoration-color: #0286e4;
}

.entry .date {
  font-size: 3vw;
  color: #ffa500;
  padding-bottom: 24px;
  margin-bottom: 16px;
  margin-botoptom: 0;
}
.entry.retracted .date {
  text-decoration: line-through;
  text-decoration-color: #ffa500;
}

.entry .retracted {
  font-family: 'Almendra', serif;
  color: #0083e0;
  font-size: 3.5vw;
  text-decoration: none;
  text-transform: capitalize;
  margin-bottom: 16px;
  margin-top: 0;
}

.entry p {
  color: #fff;
}
.entry.retracted p {
  text-decoration: line-through;
  text-decoration-color: #fff;
}

.entry .conclusion {
  color: #b10909;
  margin: 48px 5vw;
  font-size: 13vw;
  font-family: 'Almendra', serif;
  text-transform: capitalize;
}
.entry.retracted .conclusion {
  text-decoration: line-through;
  text-decoration-color: #b10909;
}

.entry .retraction {
  color: #fff;
  font-size: 3.5vw;
  text-decoration: none;
}

.entry a:not(.anchor) {
  color: #b10909;
}
/*
@media (max-width: 600px) {
  .entry .conclusion {
    font-size: 8em;
  }
}

@media (min-width: 960px) {
  .entry .conclusion {
    font-size: 9em;
  }
}

@media (min-width: 1280px) {
  .entry .conclusion {
    font-size: 15em;
  }
}
*/