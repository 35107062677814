.celebration {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.celebration .pad {
  display: none;
  flex-grow: 1;
}


.celebration .img-1 {
  display: none;
}

.celebration .img-2 {
  display: none;
}

@media (min-width: 1280px) {
  .celebration {
    flex-direction: row;
  }
  .celebration .img-1 {
    display: inline-block;
  }
  .celebration .pad {
    display: inline-block;
  }
}

@media (min-width: 1750px) {
  .celebration .img-2 {
    display: inline-block;
  }
}
